@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";



.main{
    width: 100%;
    .secTitle{
        .title{
            position: relative;
            width: max-content;
            color: var(--textColor);
            margin: 1rem 0;
            z-index: 2;
            &::after{
                position: absolute;
                content: "";
                background: var(--SeconadaryColor);
                height: 5px;
                width: 120px;
                right: 0;
                bottom: 5px;
                z-index: -1;
            }
        }
    }
    .secContent {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
       
   .singleDestination{
    height: 100%;
    display: grid;
    width: 330px;
    row-gap: 10px;
    border-radius: 10px;
    align-items: center;
    background: white;
    box-shadow: 0 2px 4px rgba(140,140,141 , 0.549);
    overflow: hidden;
    &:hover{
        background: rgba(255, 255, 255, 0.893);
        box-shadow: 1 4px 4px  rgba(85,85,114, 0.549);
        transition: 0.3 ease;
    }
.imageDiv{
    height: 260px;
    width: 100%;
    overflow: hidden;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 2s ease;
    }
    &:hover{
        img{
            transform: scale(1.1);
        }
    }
}



.cardInfo{
    padding: 1rem;
    .destTitle{
        color: black;
        font-size: 1.25rem;
    }
    .continent{
        gap: 0.5rem;
        .icon{
            color: var(--textColor);
            font-size: 18px;
        }
        .name{
            color: var(--textColor);
            font-weight: 400;
            font-size: 14px;
        }

    }

    .fees{
        width: 100%;
        justify-content: space-between;
        padding: 1rem;
        margin: 1rem 0;
        border-top: 1.5px solid #808080	;
        border-bottom: 1.5px solid #808080	;
.grade{
color: var(--textColor);
max-width: 100px;
line-height: 20px;
display: flex;
align-items: center;
justify-content: center;
small{
    text-align: center;
    border-radius: 1rem;
    color: var(--whiteColor);
    padding: 1px 10px;
    font-size: 10px;
}



}
    }
}

   }


      }

      .items{
        display:flex;
        justify-content: space-around;
        width:75%;
        list-style: none;
        margin-left:  60px ;
      }
      
  
     
      
      
}


.v1{
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 140px;
  border:1px solid #999;
  border-left: none;
  border-right: none;

  align-items: center;
}
.k1 h5{
  text-align: start;
}
.k1 p{
  text-align: start;
}


@media screen and (min-width:1025px) and (max-width:1440px)  {

.tak{
  position: relative;
  top: 50px;
}

}
@media screen and (min-width:1442px) and (max-width:2559px)  {

.main{
  position: relative;
  top: 130px;
}
.v1{
  position: relative;
  top: 60px;
 
  
}
  
  }
@media screen and (min-width:2560px) {
  .main{
    position: relative;
    top: 1000px;
  }

  .v1{
    position: relative;
   top: 400px;
    
  }
}
@media screen and (min-width:769px) and (max-width:1024px)  {
.v1{
  position: relative;
  top: 100px;
}
.tak{
  position: relative;
  top: 50px;
}
  }

  @media screen and (min-width:426px) and (max-width:768px) {
    .tak{
      position: relative;
      top: 70px;
    }

  }
 



@media screen and (max-width:768px) {

  .tak{
    position: relative;
 
    /* top: 100px; */
  }


  .v1{
    width: 90%;
    flex-direction: column;
     justify-content: space-around;

   position: relative;
   top: 80px;
  padding-top: 15px;
  padding-bottom: 15px;

  }
  .k1{
    /* width: 80%; */
    text-align: center;
  }
  .k1 h5{
    text-align: center;
  }
  .k1 p{
    text-align: center;
  }
  
  .secContent {
    grid-template-columns: 1fr; /* Display as a single column */
   
  }
  .items {
     display: none;
    }
  
    
    
}
 


.Ap {
  text-align: center;
  width: 80%;

  
  
  
}

.slide img{
width:20rem;
margin: 0 auto;
border-radius: 10px;

}

.slide{
  transform: scale(0.7);
  transition: transform 300ms;
  opacity: 0.5;
  border-radius: 10px;
}

.activeSlide{

  transform: scale(1.2);
  opacity: 1;
}

.arrow{
  background-color: #fff;
  position: absolute;
cursor: pointer;
z-index: 10;
}

.arrow svg{
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}
@media    (min-width: 1441px) and  (max-width: 2300px) {
  .tak{
    margin-top: 200px;
  }
}
/* @media screen  and (min-width: 426px) and  (max-width: 768px) {
  .tak{
    margin-top: 10px;
  }
} */

@media screen  and (min-width:2301px){
  

  .tak{
  display: none;
}

}
@media screen and (min-width:664px) and (max-width:768px){
  .tak{
    margin-top: 30px;
  }

}
