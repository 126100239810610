@font-face {
  font-family: 'Tw-Cen-MT';
  src: url('Tw-Cen-MT/Tw Cen MT.ttf') format('truetype'),
       url('Tw-Cen-MT/Tw Cen MT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Tw-Cen-MT', sans-serif;

}
#cater {
  width: 120px;
}
#dest{
  width: 130px;
}
:root{
--PrimaryColor:hsl(199,100%,33%);
--SecondaryColor:hsl(197,85%,43%);
--gradientColor:linear-gradient(to right,hsl(197,85%,43%),hsl(199,100%,33%));
---whiteColor:hsl(0,0%,100%);
--blackColor:hsl(201,33%,16%);
--textColor:hsl(240,4%,36%);
--whiteColorDeam:hsl(0,0%,93%);
--greyText:rgb(190,190,190);
--inputColor:rgb(239,239,239);
--bodyColor:rgb(240,240,246);
--cardBG:rgb(225,225,235);
}
a{
  text-decoration: none;
}
li{
  list-style: none;
}
.section{
padding: 4rem 0 2rem;
}
.container{
  padding-left: 1.5rem;
  padding-right: 1.5rem;

}
.icon{
  font-size: 2rem;
  cursor: pointer;
}
.flex{
  display: flex;
  align-items: center;
}
.grid{
  display: flex;
  align-items: center;
  
}
.btn{
  padding: .6rem 1.5rem;
  background: var(--gradientColor);
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
}
.btn a{
  color: var(---whiteColor);
  font-weight: 500;
}
.btn:hover{
  background: var(--SecondaryColor);
}
img,video{
  width: 100%;
  height: auto;
}
/* input{
  font-size: 100%;
  height: 45px;
 background: #fdfefe;
padding: 20px;
border-radius: 10px;
}
input:focus{
  outline: none;
} */
body{
  /* background: var(--bodyColor); */
  
  background: white;
}
html{
  scroll-behavior: smooth;
}