.divl{

   background: black ;
   width: 100%;
    min-height: 160px;
    padding: 15px 0;
    .g2{
        color: #999;
  margin: 0 auto;
  padding: 7px;
  max-width: 1200px;
  .lis{
    list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
.lid{
    flex-basis: calc(20% - 20px);
  margin-bottom: 40px;

}
  }

    }
}

#he2{
    font-size: 13px;
    margin: 20px 0 5px;
    font-weight: 700;
  
}
#he3,#he4{
    font-size: 13px;
  margin: 20px 0 5px;
  font-weight: 700;
  overflow: hidden;
}

#l2,#l3,#l4{
    flex-basis: calc(20% - 20px);
    margin-bottom: 40px;
}
#he5,#he6,#he1,#he7,#he8{
    font-size: 13px;
  font-weight: 700;
  margin-bottom: 10px;
}
.en,.dib,.DIV4,.divl{
    font-size: 13px;
  line-height: 1.2;
}
.soc{
    display: flex;
    justify-content: space-between;
    width: 100px;
}


@media screen and (min-width:2560px) {
  .divl{
    position: relative;
    top: 1000px;
  }
}

