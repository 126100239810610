@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.Ap {
  text-align: center;
  width: 100%;
  /* margin: 3rem auto; */
  align-items: center;
  justify-content: center;
height: 250px;
  position: relative;

}
.b{
  margin-top: -30%;
}
.n{
  visibility: hidden;
  transition: 0.5 ease-in;
  transition-delay: 300ms;
}


.slider-container {
  height: 350px; 
}

.slide {
 
  transform: scale(0.7);
  transition: transform 300ms;
  opacity: 0.5;
  
  /* border-radius: 10px; */
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
  .b{
    display: none;
  }
}

.slide img {
  width: 20rem;
  margin: 0 auto;
  border-radius: 0px;

  /* border-radius: 10px; */
}

.activeSlide {
 
  transform: scaleX(1.2);
  transform: scaleY(1);
  opacity: 1;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);

  height: 300px;
 
  .b{
    display: none;
    transition: 0.5 ease-in-out;
  }
}
.activeSlide:hover{
  .b{ 
   display: block;
   

  }
}
.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

.icon {
  font-size: 1.3rem;
  cursor: pointer;
}
#pa{
  display: inline;
  font-size: 13px;
  font-weight: normal;
  
}



@media    (max-width: 768px)  {
  .Ap {
   width: 100%;
  height: 130px;
  }
  .slide {
 
   width: 100%;
   height: 100%;
   border-radius: 0px ;

  }
  .slide img{
    width: 100%;
    height: 100%;
  }
  .arrow {
    display: none;
  }

  .activeSlide {
   border-radius: 0px ;
     width: 100%;
     height: 100%;
     border: none;
     
  
   }

   .activeSlide img{
    height: 100%;
    width: 100%;
  
   }
}
@media    (max-width: 430px){
.Ap{
 
}
.b{
  font-size:7px;
 position: relative;
   
}
}

@media    (min-width: 769px) and  (max-width: 1024px) {
  .Ap {
    text-align: center;
    width: 100%;

  }
  .slide {
    padding-top: 10px;
    height: 250px;
    border: 0px;
   width: 290px;
  }
  .slide img{
    width: 290px;
    height: 250px;
  }
  
  .activeSlide {
  
    height: 220px;
     width: 300px;
     border: 0px;

   }
   .activeSlide img{
    width: 300px;
    height: 220px;

   }
}

@media    (min-width: 1025px) and  (max-width: 1440px) {
  .Ap {
    text-align: center;
    width: 80%;
 



   position: relative;

   }
  .slide {
    padding-top: 10px;
    height: 250px;
    border: 0px;
   width: 290px;
  }
  .slide img{
    width: 290px;
    height: 250px;
  }
  
  .activeSlide {
  
    height: 220px;
     width: 300px;
     border: 0px;

   }
   .activeSlide img{
    width: 300px;
    height: 220px;

   }
}

@media    (min-width: 1441px) and  (max-width: 2560px) {
  .Ap {
    text-align: center;
    width: 98%;
   
    padding-bottom: 420px;
   }
  .slide {
 
  width: 100%;
  height: 100%;
  }
  .slide img{
    width: 100%;
    height: 100%;
  }
  
  .activeSlide {
  
     width: 100%;
     height: 100%;
   }
   .activeSlide img{
    width: 100%;
    height: 100%;
   }


   .b{
    font-size: 30px;
   display: flex;
   align-items: start;
  
  }
}
/* @media screen  and (min-width:2560px){
  .activeSlide {
   padding: 10px;
  }
} */
a:focus, a:active {
  outline: none;
}