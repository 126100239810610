
.home {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}



video {
  position: relative;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  object-fit: cover;
}

#hometext{
  font-size: 38px;
}



   .section {
  display: flex;
  padding-bottom: 6px;
  padding-top: 10px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.section1,
.section2 {
  width: 40%;
  margin: 0px 0px 5px;
}
.dection1,
.dection2 {
  width: 40%;
  margin: 0px 0px 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.vection {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 10px;}

.vection1,
.vection2 {
  width: 40%;
  margin: 0px 0px 3px;
}




/* @media    (min-width: 769px) and  (max-width: 1024px) {
  .home{
    position: relative;
  }
  .homeContent {
position: relative;
  }
.section{
padding-top: 380px;

}
} */

@media    (min-width: 1025px) and  (max-width: 1440px) {
  .home{
    position: relative;
  }
  .homeContent {
position: relative;
  }
.section{
position: relative;



}
.vection{
  position: relative;
  
  
  }
  





}
/* @media screen and (min-width:2560px) {
.section{
  position: relative;
  top: 1000px;
}
}
 */



@media (max-width: 768px) {
  .home{
    width: 90%;
   
  }

 .overlay, video{
    height: 300px;
  }
  .section {
    width: 90%;
    position: relative;
   

    flex-direction: column;
      align-items: flex-end;
  
  }
  #hometext{
    font-size: 19px;
  }
  .section1,
  .section2 {
    text-align: center;
    width: 90%;
  }
  .dection1,
  .dection2 {
    text-align: center;
    width: 90%;
  }
  .vection {
    position: relative;
    align-items: center;

    flex-direction: column;
  }
.vection1{
margin-left: 15px;
}
  .vection1,
  .vection2 {
    text-align: center;
    width: 90%;
  }



  .cardDiv{
    flex-direction: column;
  }
  .input{
   width: 70%;
   align-items: flex-start;
  }

  input{
    width: 200px;
    padding: none;
    margin-right: 10px;
  }
}
@media (min-width: 768px)and  (max-width: 1024px){


  #hometext{
    font-size: 26px;
  }
}

@media (min-width: 1024px)and  (max-width: 1440px){
  #hometext{
    font-size: 32px;
  }
}


.mostpopular:hover{
  text-decoration: underline;
  text-decoration-color:#FB4570;
  cursor: pointer;
  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
    }
    #vij{
     
        font-size: 100%;
        height: 45px;
       background: #fdfefe;
      padding: 20px;
      border-radius: 10px;

    
    }
    #vij:focus{
      outline: none;
    }
    @media (max-width: 768px){
      #p6,#p8,#p10,#p12{
        display: none;
        
      }
      #p7,#p9,#p11,#p13{
        display: 1;
      }



    }
    @media (min-width: 769px){
      #p6,#p8,#p10,#p12{
        display:1;
      }
      #p7,#p9,#p11,#p13{
        display: none;
      }
    }