.secContent {
    display: grid;
    width: 90%;
    justify-content: center;
    grid-template-columns: auto auto auto;
    gap: 1.5rem;
   
.singleDestination{
  
height: 100%;
display: grid;
width: 330px;
row-gap: 10px;
border-radius: 0px;

align-items: center;
background: white;
box-shadow: 0 2px 4px rgba(140,140,141 , 0.549);
overflow: hidden;

&:hover{
    background: rgba(255, 255, 255, 0.893);
    box-shadow: 1 4px 4px  rgba(85,85,114, 0.549);
    transition: 0.3 ease;
}
.imageDiv{
height: 100%;
width: 100%;
overflow: hidden;
img{
    height: 100%;
    width: 100%;
    /* object-fit: cover; */
    transition: 2s ease;
}
&:hover{
    img{
        transform: scale(1.1);
    }
}
}

.cardInfo{
padding: 1rem;
.destTitle{
    color: black;
    font-size: 1.25rem;
}


}


}

}


 
 
 @media screen and (max-width: 320px) {
    .singleDestination{
        padding: 15px;
    }
}
 
 @media screen and (max-width: 768px) {
    .secContent {
      grid-template-columns: 1fr; /* Display as a single column */
      padding-right: 10px;
      padding-left: 10px;
     
    }
}
@media screen and (min-width: 426px) and (max-width: 1020px) {
    .secContent {
      grid-template-columns: 1fr 1fr; /* Display as a single column */
     
    }

}
.ik:focus{
outline: none;
}