
@font-face {
  font-family: 'Tw-Cen-MT';
  src: url('Tw-Cen-MT/Tw Cen MT.ttf') format('truetype'),
       url('Tw-Cen-MT/Tw Cen MT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Tw-Cen-MT', sans-serif;

}

nav{
  position: fixed;
  z-index: 900;
  width: 100%;

  background: white;
  /* background: #242526; */
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Adjust the values for your desired shadow effect */

}
nav .wrapper{
  position: relative;
  max-width: 1300px;
  padding: 0px 30px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo img{
 
  margin-top: 30px;

}
.wrapper .nav-links{
  display: inline-flex;
}
.nav-links li{
  list-style: none;
}
.nav-links li a{
  /* color: #f2f2f2; */
    color: black;
  
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links li a:hover{
  /* background: #3A3B3C; */
  background: white;
}
.nav-links .mobile-item{
  display: none;
}
.nav-links .drop-menu{
  position: absolute;
  /* background: #242526; */
  background: white;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}

/* Initially hide mega-box1 */
/* Add styles for the mega menu */




.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
  
}
.drop-menu li a:hover{
  color:rgb(252, 47, 115);
}
.mega-box{
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  align-items: center;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}

.mega-box .content{
  /* background: #242526; */
  /* background: white; */
  background: white;
  padding: 25px 20px;
  display: flex;
  color: #242526;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.mega-menu2{
  position: absolute;
  right: -165px;
  top: 2px;
  background: #fff;
  display: none;
}
.mega-hover:hover {
  .mega-menu2{
  display: block;
}
}



.mega-box .content .row{
  width: calc(25% - 30px);
  line-height: 45px;
}

.content .row img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header{
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}
.content .row .mega-links{
  margin-left: -40px;
}
.row .mega-links li{
  padding: 0 20px;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  /* color: #d9d9d9; */
  color: black;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover{
  /* color: #f2f2f2; */
  color:rgb(252, 47, 115);
}
.wrapper .btn{
  color: #020202;
  background: transparent;
border-radius: 0px;

padding: none;
  font-size: 12px;
  cursor: pointer;
  display: none;
   height: 10px;
   
}
.wrapper .btn p{
  position: absolute;
  top: -15px;
  left: 10px;
  color: white;
}

.wrapper .btn.close-btn{
  position: absolute;
  right: 30px;
     
 
padding: none;
  top: 10px;
}



  /* Define a class for active links */
  #active-link {
    text-decoration: underline;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color:#FB4570;
    cursor: pointer;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
  #aj{
    display: none;
  }
@media screen and (max-width: 970px) {
  .wrapper .btn{
    display: block;
  }
  #pink{
    display: none;
  }
  
#aj{
  display: block;
}


  .wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: white;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;

    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
  }

  .wrapper .nav-links li a{
     color:  #242526 ;
    
  
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }
  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn{
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn{
    display: block;
  }
  .nav-links li{
    margin: 15px 10px;
  }
  .nav-links li a{
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 300px;
    max-height: 0px;
    overflow: hidden;
    background:#f8f8f8;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box{
    max-height: 100%;
   background: white;
   width: 300px;
  }
  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: #090909;
    font-size: 20px;
    font-weight: 500;
    /* padding-left: 20px; */
    cursor: pointer;
    background: white;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
  
  }
  .drop-menu li{
    margin: 0;
    

  }
  .drop-menu li a{
    border-radius: 5px;
    font-size: 18px;
    
   
  }
  .drop-menu ul{
 
   background: #242526;
  
   
  }
  .mega-hover:hover {
    .mega-menu2{
    border: none;
    background: white;
  }
}
  
  
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
  
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content{
    box-shadow: none;
   
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row{
    width: 100%;
    /* margin-bottom: 15px; */
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }

  .mega-menu2{
    position: relative;
    left: -10px;
    width: 300px;
     

  }
 
.drop-menu{

  width: 400px;
  background: #f8f8f8;

}


.popup{
  position: relative;
 
  height: 100%;
  width: 80%;
}






}




nav input{
  display: none;
}

.body-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div{
  font-size: 45px;
  font-weight: 600;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;

  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other elements */
 /* grid-template-columns: 1fr; */
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  width: 100%;
  text-align: center;
  

  justify-content: space-around;
 

}
.form-group{
  width: 70%;

 
  
 
}
.popup button {
 
 
  
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}