#s
{ 
  height: calc(100vh - 66px);
    object-fit:cover;
     width: 100% 
}



.he {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adjust shadow properties as needed */
  color: white; /* Set text color to white */
}

h1 {
  margin: 0;
  padding: 0;
}






.nav2{
    width: 100%;
    background: rgb(245, 244, 244);
    height: 60px;

}
.fhotel{
  display: flex;
justify-content: center;
}
.div4 {
  max-width: 1200px;
  display: flex;
  font-size: 15.5px;
  line-height: 1.6rem;
  justify-content: space-around;
  margin: 0 auto;
}

.div3{
    padding: 10px;
    background: rgb(245, 244, 244);
    width: 60%;
 justify-content: space-around;
    display: flex;
height: 40px;
font-weight: 600;
font-size: 14px;
}

.dimg1,.dimg2{
    width:470px;
    height:100px

}
.sec1,
.sec2 {
  width: 528px;
}
.d1,.d2{
    width: 480px;
  }
  .d2{
    height: 320px;
  }
  .d21{
    padding:20px
  }
  .hotel{
    display:flex;
    justify-content: center;
    width:80%;
    height: 500px;
  }


/* Style your paragraphs as needed */
.div3 p {
  cursor: pointer;
  color: #333; /* Default text color */
  margin: 10px 0; /* Example margin for spacing */
 
}
.div3 p:hover{
  text-decoration: underline;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color:#FB4570;
  cursor: pointer;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

/* 
@media screen and (max-width: 1440px){
  .he{
    width: 50%;
    font-size: 13px;
    transform: translate(-50%, -290%);

  }
} */

/* mobile */
@media screen and (max-width: 320px){
  .he{
    width: 50%;
    font-size: 12px;
    transform: translate(-50%, -150%);

  }
}@media screen and (min-width: 2565px){
  .he{
    width: 50%;
    font-size: 17px;
    transform: translate(-50%, -550%);

  }
}
@media screen  and  (min-width:321px) and (max-width: 1024px){
  .he{
    width: 50%;
    font-size: 14px;
    transform: translate(-50%, -150%);

  }
  .sec2{
    padding-left: 15px;
  }
}

@media screen and (max-width: 768px) {
  
  .destination_header{
    padding: 15px;
    padding-top: 35px;
  }
  .he{
    width: 50%;
    font-size: 10px;
    transform: translate(-50%, -60%);

  }
  .nav2{
    display: none;
  }
    .div3{
        display: none;
    }
    .hotel{
      flex-direction: column;
      height: 100%;
      width: 300px;
     gap: 25px;
    }
   
    .div4 {
      display: flex;
      flex-direction: column;

    }
    .dimg1,.dimg2{
      width:280px;
      height:100px
  
  }
  
    .sec1,
    .sec2 {
      width: 277px;
      margin: 0 auto;
      /* padding-left: 15px; */
    }
    .fhotel{
    
     flex-direction: column;
     align-items: center;

     }
.d1,.d2{
    width: 277px;
}
.div51{
 
flex-direction: column;
 }

  }
  
  center h2 {
    /* CSS styles for <h2> elements within <center> */
   padding: 0px 15px;
  }
  
  center strong p {
    padding: 0px 15px;
  }