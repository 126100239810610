/* MonthGrid.css */
/* ... your existing styles ... */

/* Add styles for the month grid container */
.month-grid-container {
    max-height: 400px; /* Set your desired fixed height */
    overflow-y: auto; /* Use "auto" to enable vertical scrolling when needed */
    border: 1px solid #ccc; /* Add a border to create a scrollable box */
    border-radius: 10px;
   background: white;
  }
  /* .month-grid-container::-webkit-scrollbar {
    width: 0;
    background: transparent;
  } */
  
  .month-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid */
    grid-gap: 20px;
    max-width: 100%; 
    background: white;
    /* width: 100px; */
    margin: 0 auto; /* Center the grid horizontally */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
  }
  
  .month-item {
    background: white;
    padding: 5px; /* Adjust padding to your preference */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 16px;
    height: auto; /* Allow the height to adjust to content */
    line-height: 1.4; /* Improve text spacing */
  }
  
  .month-item:hover {
   
    color:rgb(252, 47, 115);

  }
  @media screen and (max-width:768px) {

    .month-grid{
    
border: none;
width: 100px;
    }

    



    .month-item {

    }
  }