.secContent {
    display: grid;
    width: 90%;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
   
.singleDestination{
height: 100%;
display: grid;
width: 330px;
row-gap: 10px;

border-radius: 10px;
align-items: center;
background: white;
box-shadow: 0 2px 4px rgba(140,140,141 , 0.549);
overflow: hidden;

&:hover{
    background: rgba(255, 255, 255, 0.893);
    box-shadow: 1 4px 4px  rgba(85,85,114, 0.549);
    transition: 0.3 ease;
}
.imageDiv{
height: 260px;
width: 100%;
overflow: hidden;
img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 2s ease;
}
&:hover{
    img{
        transform: scale(1.1);
    }
}
}

.cardInfo{
padding: 1rem;
.destTitle{
    color: black;
    font-size: 1.25rem;
}
.continent{
    gap: 0.5rem;
    .icon{
        color: var(--textColor);
        font-size: 18px;
    }
    .name{
        color: var(--textColor);
        font-weight: 400;
        font-size: 14px;
    }

}

.fees{
    width: 100%;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem 0;
    border-top: 1.5px solid #808080	;
    border-bottom: 1.5px solid #808080	;
.grade{
color: var(--textColor);
max-width: 100px;
line-height: 20px;
display: flex;
align-items: center;
justify-content: center;
small{
text-align: center;
border-radius: 1rem;
color: var(--whiteColor);
padding: 1px 10px;
font-size: 10px;
}



}
}
}

}


 } 
 
 
 
 @media screen and (max-width: 768px) {
    .secContent {
        
      grid-template-columns: 1fr; /* Display as a single column */
      padding-right: 10px;
      padding-left: 10px;
     
    }
}
@media screen and (min-width: 426px) and (max-width: 1020px) {
    .secContent {
      grid-template-columns: 1fr 1fr; /* Display as a single column */
     
    }

}
@media screen and (max-width: 320px) {
    .singleDestination{
        padding: 15px;
    }
}