#ap{

width: 50%;
text-align: center;
}


.sect {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .sect1,
  .sect2 {
    width: 40%;
    margin: 0px 0px 22.5px;
  }

  @media    (min-width: 769px) and  (max-width: 1024px) {
    
.sect{
  position: relative;
  top: 100px;

}
  }
 
  @media    (min-width: 1025px) and  (max-width: 1440px) {
   
.sect{
  position: relative;
  bottom: -120px;

}

@media    (min-width: 1441px) and  (max-width: 2560px) {
 
.sect{
  border: 1px solid red;
position: relative;
bottom: -100%;

}
}



  }
  @media (max-width: 768px) {
   
  
  
    /* .sect {
      position: relative;
      top: -220px;
      flex-direction: column;
    } */
/* .sect1{
  margin-left: 15px;
} */
    .sect1,
    .sect2 {
      text-align: center;
      width: 90%;
    }
   
  }
