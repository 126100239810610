.header{
    position: fixed;
    display: flex;

    justify-content: space-around;
    align-items: center;
/*   
     background: var(--whiteColorDeam); */
     background: white;
    width: 100%;
    height: 80px;
    padding: 0.7rem;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);


    .logo {
        color: var(--blackColor);
        font-weight: 600;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        .icon{
            font-size: 25px;
            color: var(--PrimaryColor);
            transform: translateY(5px);
        }
    }


    @media screen and (max-width: 768px) {
        .navBar {
            position: absolute;
            background:var(--whiteColorDeam);
            height: max-content;
            width: 80%;
            border-radius: 1rem;
            top: -500rem;
            left: 50%;
            padding: 1rem;
            transform: translate(-50%);
            z-index: 2000;
            box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
            transition: 0.5s ease-in-out;
        
        .navLists{
            flex-direction: column;
            align-items: center;
            margin: auto;
            padding: 0.5rem 0;

            .navItem{
                padding: 0.5rem 0;
                .navLink{
                    color: var(--textColor);
                    font-size: 0.9rem;
                    font-weight: 600;
                    &:hover{
                     
                        color: var(--PrimaryColor);
                    }
                }
               }

               .btn{
                margin-top: 1rem;
                a{
                    font-weight: 600;
                    color: var(--whiteColor);
                }
               }


               
        }
.closeNavbar{
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: var(--PrimaryColor);
    &:hover{
        color: var(--SecondaryColor);
    }
}


    }

    .activeNavbar{
        top: 7rem;
    }
}
.toggleNavbar{
    .icon{
        font-size: 25px;
        color: var(--PrimaryColor);

    }

    &:hover .icon{
color: var(--SecondaryColor);

    }
}



}
@media screen and (min-width:769px) {
    .toggleNavbar,.closeNavbar{
        display: none;
    }
    .header{
        padding: 1.5rem 2rem;
        .navBar{
            .navLists{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                .navItem{
                    .navLink{
                        color: var(--textColor);
                        padding: 0 0.45rem;
                        font-size: 0.9rem;
                        font-weight: 500;
                        &:hover{
                            color: var(--PrimaryColor);
                        }
                    }
                }

                .btn{
                    margin-left: 1rem;
                    a{
                        color: var(--whiteColor);
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
.custom-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
   
    transition: background-color 0.3s, color 0.3s;
  }
  
  .custom-button:hover {
    background-color: white;
    color: black;
    border:1px solid black;
  }
  .custom-button1{
    
    background-color:white;
    color: black;
   
    padding: 10px 20px;
    font-size: 15px;
    cursor: pointer;
  
    transition: background-color 0.3s, color 0.3s;
  }
  
  .custom-button1:hover {
    background-color:black;
    color:white;
    border:1px solid white;
  }




  .pink-button {
    background-color: white;
    color: black;
    border:1px solid black;
    padding: 7px 20px;
   
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pink-button:hover {
    background-color: #D83F40;
    color:white;
    border: none;
  }
  

